import React from 'react';
import * as Sentry from '@sentry/nextjs';

import { getProductDetail, submitEnquiry } from 'api';
import { RequestError } from 'api/utils';
import { ENQUIRY_KEY } from 'consts';
import EnquiryContext from 'contexts/EnquiryContext';
import { EnquiryData } from 'types/Enquiry';
import { Product } from 'types/Product';
import { getLocalStorage, setLocalStorage, toast } from 'utils';

const IS_CLIENT = typeof window !== 'undefined';

const getProductIds = (): number[] => {
  if (IS_CLIENT) {
    const storedProducts = getLocalStorage(ENQUIRY_KEY);
    return Array.isArray(storedProducts) ? storedProducts : [];
  }

  return [];
};

const EnquiryProvider: React.FCWithChildren = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [token, setToken] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [email, setEmail] = React.useState('');

  const lastStoredKeys = React.useRef<string | null>();

  const stored = getProductIds();

  React.useEffect(() => {
    const storedKey = stored.join('|');

    if (open && storedKey !== lastStoredKeys.current) {
      lastStoredKeys.current = storedKey;

      (async () => {
        try {
          setLoading(true);
          const productsResponse = await Promise.all(
            stored.map(id => getProductDetail({ id: String(id) })),
          );

          const products: Product[] = [];

          productsResponse.forEach(product => {
            if (product.data) {
              products.push(product.data);
            }
          });

          setProducts(products);

          setLoading(false);
        } catch (error) {
          if (error instanceof RequestError) {
            Sentry.captureException(error);
            toast();
            setLoading(false);
          }
        }
      })();
    }
  }, [open, stored]);

  React.useEffect(() => {
    if (!open) {
      // Reset when closing
      lastStoredKeys.current = null;
    }
  }, [open]);

  const addProduct = React.useCallback((products: number[]) => {
    const newState: number[] = Array.from(new Set([...getProductIds(), ...products]));

    setSent(false);
    setLocalStorage(ENQUIRY_KEY, newState);
    setOpen(true);
  }, []);

  const removeProduct = React.useCallback(
    (productId: number) => {
      const stored = getProductIds();

      setLocalStorage(
        ENQUIRY_KEY,
        stored.filter(id => id !== productId),
      );
      const newProducts = products.filter(({ id }) => id !== productId);
      setProducts(newProducts);

      if (newProducts.length === 0) {
        setOpen(false);
      }
    },
    [products],
  );

  const send = async ({ email, message }: EnquiryData) => {
    try {
      setLoading(true);
      const enquiry = await submitEnquiry({
        email,
        message,
        products: products.map(({ id }) => id),
      });

      if (enquiry.data) {
        const { token } = enquiry.data;

        setToken(token);
        setSent(true);
        setLocalStorage(ENQUIRY_KEY, []);
        setProducts([]);
        setEmail('');
        setMessage('');
      }

      setLoading(false);
    } catch (error) {
      if (error instanceof RequestError) {
        Sentry.captureException(error);
        toast();
        setLoading(false);
      }
    }
  };

  return (
    <EnquiryContext.Provider
      value={{
        loading,
        open,
        addProduct,
        removeProduct,
        setOpen,
        sent,
        send,
        token,
        products,
        stored,
        message,
        setMessage,
        email,
        setEmail,
      }}
    >
      {children}
    </EnquiryContext.Provider>
  );
};

export default EnquiryProvider;
